<template>
    <div class="device-app flex flex-y flex-x">
        <div class="device-box">
            <input type="text" placeholder="输入激活码" v-model="code" />
            <button @click="codefun">激活</button>
        </div>
    </div>
</template>
<script>
    import "./new_test.scss";
    import { postBindCode } from "../services/api";
    export default {
        data() {
            return {
                code:''
            }
        },
        methods: {
            codefun:function(){
                var _this = this;
                postBindCode({
                    code: this.code,
                    mac: this.$route.query.mac,
                    user_id: this.$route.query.user_id
                }).then((data)=>{
                    if(!data.code){
                        _this.$router.push({
                            path: "/",
                            query: { 
                                mac: this.$route.query.mac, 
                                user_id: this.$route.query.user_id,
                                order_code: this.$route.query.order_code
                            },
                        });
                    }
                })
            }
        },
        mounted(){
            if (!this.$route.query.user_id) {
                window.location.href = `${LOGIN_URL}?order_code=${this.$route.query.bud_e}`;
            }
        }
    }
</script>