<template>
  <div class="report-title-container">
    <div class="title">{{ title }}</div>
    <div></div>
    <div class="sub-title">{{ subTitle }}</div>
  </div>
</template>
<script>
export default {
  props: ["title", "subTitle"],
};
</script>
