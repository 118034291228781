<template>
  <div class="report-trend" v-if="!loading && reports.data.length > 0">
    <Trend :data="history" :ignoreTitle="true" />
  </div>
  <div class="gray-nobg3">
    <div v-if="loading" class="report-list-loading">
      <van-loading size="50px" color="#00c5cd" />
    </div>
    <div class="report-list-container" v-if="!loading && reports.data.length > 0">
      <!-- <div class="title">近期健康趋势</div> -->
      <van-list class="list"
                v-model="ajaxload"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
      >
        <div
          class="item"
          v-for="item in reports.data"
          v-bind:key="item.id"
          @click="onGoTo(item.id)"
        >
          健康状态
          <span :class="classNames(item.score)">{{ item.result_text }}</span>
          <span class="time">{{ item.create_at }}</span>
        </div>
      </van-list>
      <!-- <div class="operator">
        <Button type="primary" @click="onStart">开始评估</Button>
      </div> -->
    </div>
    <div
      class="report-empty-container"
      v-if="!loading && reports.data.length === 0"
    >
      <!-- <div class="title">近期健康趋势</div> -->
      <div class="title"></div>
      <div class="empty">
        <div class="img">
          <img src="/assets/error/empty.png" alt="" />
        </div>
        <div class="tips">您还没有进行过检测哦 ~</div>
      </div>
    </div>
  </div>
  <div class="hu-ask" v-if="!loading && reports.data.length > 0">
    <div class="report-list">
      <van-list
        v-model="ajaxload"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="flex report-item flex-y" v-for="item in reports.data" v-bind:key="item.id">
          <div class="report-left">
            <div class="report-score">{{item.score}}分{{item.result_text}}</div>
            <div class="report-date">{{item.create_at}}</div>
          </div>
          <div class="flex-1">{{item.sick_text}}</div>
          <div @click="onGoTo(item.id)" class="report-result">查看结果</div>
        </div>
      </van-list>
    </div>
  </div>
  <div class="gray-ask flex flex-y flex-x" v-if="!loading && reports.data.length === 0">
      <div class="no-data">
        <img src="/assets/error/empty.png" alt="" />
        <div class="no-empty">暂无数据</div>
      </div>
  </div>
</template>
<script>
import { getHistory , getToken , getReportList } from "@/services/api";
import Trend from "../components/report/trend";

export default {
  data() {
    return {
      history: [],
      reports: { data: [] },
      loading: false,
      ajaxload:false,
      finished: false,
      useid: "",
      page:1,
      page_count:2,
      user_id:''
    };
  },
  components: { Trend },
  mounted() {
    this.getUserId(this.$route.query.phone,this.page)
  },
  methods: {
    classNames: function (score) {
      if (+score > 80) {
        return "result success";
      } else if (+score > 40) {
        return "result warning";
      } else {
        return "result danger";
      }
    },
    onLoad:function(){
      if(this.page<this.page_count && !this.ajaxload ){
        this.page = this.page+1;
        this.ajaxload = true;
        this.getList(this.useid);
      }
    },
    //手机号去换user_id
    getUserId:function(value){
      let _this = this;
      getToken(value).then((data)=>{
        if (data.code === 0) {
          _this.useid = data.data;
          _this.getMessage(data.data,this.page);
        }
      })
    },

    getMessage: function (value,page) {
      getHistory(value).then((data) => {
        if (data.code === 0) {
          this.history = data.data.list;
        }
      });
      this.getList(value,page);
    },
    getList:function(value,page){
      let page_size = 10;
      getReportList(value, page, page_size)
        .then((data) => {
          if (data.code === 0) {
            this.ajaxload = false;
            let reports = this.reports;
            reports.data = reports.data.concat(data.data.data);
            this.reports = reports;
            this.page_count = data.data.page_count;
            if(this.reports.data.length >= data.data.total){
              this.finished = true;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onStart: function () {
      this.$router.push({
        path: "/face",
        // order_code, user_id, user_phone
        query: { ...this.$route.query, user_id: this.useid },
      });
    },
    onGoTo: function (id) {
      this.$router.push({
        path: "/report",
        // order_code, user_id, user_phone
        query: { ...this.$route.query, user_id: this.useid, id: id },
      });
    },
  },
};
</script>
